#root button.amplify-button--primary {
    background-color: var(--orange);
}

#root [data-amplify-authenticator]{
    height: 80%;
}

#root [data-amplify-container] .amplify-tabs [data-state="active"]{
    color: var(--orange);
    border-color: var(--orange);
}

#root [data-amplify-container] [data-amplify-footer] button {
    color: var(--orange);
}

#authorizer-logo-container {
    width: 100%;
    display: flex;
    justify-content: center;
}