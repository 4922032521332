#orders-filter {
    margin-bottom: 31px;
    width: 90%;
}

#orders-filter h3 {
    margin-bottom: 5px;
    margin-top: 5px;
}

#filters {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
}

.filter-input {
    display: flex;
    flex-direction: column;
}

.filter-input span {
    margin-bottom: 5px;
}