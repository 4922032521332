.create-order-modal .ant-modal-body {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.create-order-modal .amazops-input {
    margin-top: 10px;
}

.create-order-modal .ant-modal-title {
    font-size: 1.3rem;
    font-weight: 700;
    text-align: center;
}

.create-order-product-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}