#orders {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

#orders h2 {
    margin: 0;
}

#orders .ant-table {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
}

#new-order-button-container {
    width: 90%;
    display: flex;
    justify-content: flex-end;
}

#new-order-button-container button {
    background-color: var(--green-button);
}

#new-order-button-container .ant-skeleton {
    display: flex;
    justify-content: flex-end;
}

a.delete-order {
    color: var(--red);
    font-weight: 700;
}

#orders-table-container {
    width: 90%;
}

#orders-table-container h3 {
    margin-bottom: 5px;
    margin-top: 0;
}