#imports {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

#imports h2 {
    margin: 0;
}

#imports #orders-filter {
    margin-bottom: 30px;
}

.import-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
}

.import {
    width: 100%;
    margin-top: 26px;
    text-decoration: none;
    color: black;
}

.import h3 {
    margin-top: 0;
    margin-bottom: 5px;
    text-align: left;
}

.import-progress {
    width: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.import-progress .ant-progress {
    width: 95%;
    margin-right: 0;
}

.import-progress .progress-dates {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.import-progress .progress-dates .days-left {
    font-weight: 700;
}

.import-progress .ant-progress-bg {
    background-color: var(--orange);
}

.import-display .import-details {
    display: flex;
    width: 100%;
}

.import-details .import-detail {
    margin-right: 30px;
}

.import-details .import-detail .detail-title {
    font-weight: 700;
}

.ant-empty {
    margin-top: 50px;
}