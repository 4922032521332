.step-display {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
    width: 740px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 45px;
}

.step-display.active, .step-display.edited {
    background: white;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
    border: none;
}

.step-display.completed {
    background: #EBFFDB;
    border: 2px solid var(--green-button);
    box-shadow: none;
}

.step-display.inactive {
    background: var(--grey);
    opacity: 0.7;
    box-shadow: none;
}

.step-display p.title {
    margin: 0;
    font-weight: 700;
    font-size: 1.1rem;
}

.step-display p.description {
    margin-top: 0;
    margin-bottom: 20px;
}

.step-display p {
    text-align: center;
}

.step-display .ant-slider{
    margin-top: 60px;
    width: 90%;
}

.step-display .ant-slider:hover .ant-slider-track {
    background-color: var(--green-button);
}

.step-display .ant-slider-track, .step-display .ant-slider-track:hover, .step-display .ant-slider-track:focus-visible {
    background-color: var(--green-button);
}

.step-display .ant-slider:hover .ant-slider-handle::after {
    box-shadow: 0 0 0 2px var(--green-button);
}

.step-display .ant-slider-handle::after, .step-display .ant-slider-handle:hover::after, .step-display .ant-slider-handle:focus::after {
    box-shadow: 0 0 0 2px var(--green-button);
}

.step-display .add-box-button-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.step-display .add-box-button-container .success-button {
    background-color: var(--green-button);
    color: white;
}

.step-display .step-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    width: 100%;
}

.step-display .step-actions .continue-button {
    margin-left: 10px;
}

.step-display .sku-labels-info {
    font-weight: 700;
    margin-top: 30px
}

.step-display .step-input {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.step-display .step-input span {
    font-size: 0.8rem;
    font-weight: 700;
}

.step-display .step-input .ant-input-number {
    width: 100%;
}

.step-display .step-input.production-start-date-input {
    margin-bottom: 10px;
}

.step-display .step-progress {
    width: 90%;
    margin-top: 20px;
    margin-right: -15px;
}

.step-display .step-progress .ant-progress-bg {
    background-color: var(--orange);
}

.step-display .progress-dates {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.step-display .box-information-wrapper {
    margin-bottom: 20px;
}

.step-display .box-information-wrapper .ant-alert {
    padding: 10px;
    margin-top: 10px
}

.step-display .ant-alert {
    padding: 10px;
    margin: 0;
}

.step-display .box-information {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.step-display .box-information .step-input {
    width: 86px;
    min-width: 86px;
    margin-left: 5px;
}

.step-display .box-information button {
    background-color: var(--red);
    margin-left: 5px;
}

.step-display .box-information span {
    margin-bottom: 6px;
    font-size: 0.8rem;
}

.step-display .box-information .ant-divider {
    height: 50%;
    margin-left: 12px;
}

.step-display .copy-tracking-number-container {
    display: flex;
    align-items: flex-end;
}

.step-display .copy-tracking-number-container button {
    background-color: var(--orange);
    margin-left: 5px;
}

.step-display .tracking-number-confirmation-text {
    font-weight: 500;
    margin-top: 5px;
}

.step-display .complete-transport-payment-container {
    display: flex;
    flex-direction: column;
}

.step-display .transport-type {
    margin-bottom: 10px;
}

.step-display .information-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
}

.ant-slider-tooltip .ant-tooltip-inner::after {
    content: '%';
    position: relative;
    top: 0;
    left: 2px;
    z-index: 1;
  }

  .step-display .ant-result {
    padding: 0;
  }