#settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

#settings h2 {
    text-align: left;
    width: 100%;
    margin-bottom: 10px;
}

#settings h3 {
    margin: 0;
}

#settings-section {
    display: flex;
    justify-content: flex-start;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
}

.user-settings .amazops-input {
    align-items: flex-start;
    margin-bottom: 30px;
}

.user-settings .amazops-input .update-setting-button-container {
    width: 100%;
    text-align: right;
    margin-top: 10px;
}

.update-setting-button-container .amazops-button.continue-button {
    margin-left: 10px;
}

.user-settings .email-verified-icon {
    color: var(--green-button);
}

.user-settings .email-not-verified-icon {
    color: var(--red);
}

#settings .ant-divider {
    height: 100%;
    margin-left: 20px;
    margin-right: 20px;
}