:root {
  --dark-grey: #131313;
  --grey: #B1B1B1;
  --light-grey: #F2F2F2;
  --orange: #F65516; 
  --green-button: #64C518; 
  --red: #E03C3C; 
  --side-bar-width: 15rem;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--light-grey);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  height: 100%;
  margin: 0;
  line-height: 1.5;
  color: #121212;
}
textarea,
input,
button {
  font-size: 1rem;
  font-family: inherit;
  border: none;
  border-radius: 8px;
  padding: 0.5rem 0.75rem;
  background-color: white;
  line-height: 1.5;
  margin: 0;
}

#root {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

#root .ant-float-btn-body {
  background-color: var(--orange);
}

#topbar {
  min-height: 50px;
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--orange);
  box-shadow: 100px 0px 20px 0px rgba(0, 0, 0, 0.2);
}

#topbar > * {
  padding-left: 2rem;
  padding-right: 2rem;
  color: var(--light-grey)
}

#topbar #right-section {
  display: flex;
  align-items: center;
}

#topbar .topbar-menu-item {
  margin-left: 10px;
  color: var(--dark-grey)
}

#topbar .topbar-menu-item p {
  font-weight: 500;
  font-size: 0.9rem;
}

#topbar .topbar-menu-item button span {
  font-weight: 700;
}

#logo-section {
  width: var(--side-bar-width);
  background-color: var(--light-grey);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#logo-section img {
  width: 150px; 
}

#main-section {
  display: flex;
  flex-grow: 1;
}

#sidebar {
  width: var(--side-bar-width);
  background-color: var(--dark-grey);
  color: var(--light-grey);
  display: flex;
  flex-direction: column;
}

#sidebar > * {
  padding-left: 2rem;
}

#sidebar nav {
  flex: 1;
  overflow: auto;
  padding-top: 1rem;
}

#sidebar nav a span {
  float: right;
  color: #eeb004;
}
#sidebar nav a.active span {
  color: inherit;
}

i {
  color: #818181;
}
#sidebar nav .active i {
  color: inherit;
}

#sidebar ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#sidebar li {
  margin: 0.25rem 0;
}

#sidebar li .ant-divider {
  background-color: var(--light-grey);
}

#sidebar nav a {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: pre;
  padding: 0.5rem 0.5rem 0.5rem 30px;
  border-radius: 8px 0 0 8px;
  color: inherit;
  text-decoration: none;
  gap: 1rem;
}

#sidebar nav a:hover {
  background: var(--light-grey);
  color: var(--dark-grey)
}

#sidebar nav a.active {
  background: var(--light-grey);
  color: var(--dark-grey);
}

#sidebar nav a.pending {
  color: var(--dark-grey)
}

#detail {
  flex: 1;
  display: flex;
  padding: 2rem 4rem;
  width: 100%;
  justify-content: center;
}

#detail.loading {
  opacity: 0.25;
  transition: opacity 200ms;
  transition-delay: 200ms;
}

#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

button.amazops-button {
  border-width: 1px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
  border-color: var(--grey) !important;
  color: white !important;
}

button.amazops-button:hover {
  border-width: 1px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
  border-color: var(--grey) !important;
  color: white !important;
}

.amazops-input {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.amazops-input span {
  font-size: 0.8rem;
  font-weight: 700;
}

.amazops-input .ant-input-number {
  width: 100%;
}

.amazops-input.production-start-date-input {
  margin-bottom: 10px;
}

button.skip-button {
    background-color: var(--grey);
    color: white !important;
    font-size: 0.8rem;
    font-weight: 700;
}

button.skip-button:hover {
  background-color: var(--grey);
  color: white !important;
}

button.continue-button {
  background-color: var(--orange);
  color: white !important;
  font-size: 0.8rem;
  font-weight: 700;
}

button.continue-button:hover {
  background-color: var(--orange);
  color: white !important;
}

button.success-button {
  background-color: var(--green-button);
    color: white !important;
}

button.success-button:hover {
  background-color: var(--green-button);
    color: white !important;
}

button.delete-button {
  background-color: var(--red);
    color: white !important;
}

button.delete-button:hover {
  background-color: var(--red);
    color: white !important;
}