#calendar .ant-picker-calendar {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
}

#calendar h2 {
  margin: 0;
  text-align: center;
}

#calendar p {
  margin-top: 0;
  text-align: center;
}

.events {
  margin: 0;
  padding: 0;
  list-style: none;
}

.events span {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.9rem;
}

.events .ant-divider {
  margin-top: 3px;
  margin-bottom: 3px;
}