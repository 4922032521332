#billing {
    height: 100vh;
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
}

#billing .decorative-orange-bar {
    height: 50vh;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: var(--orange);
    z-index: -1;
}

#billing .pricing-table-wrapper {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
    width: 50%;
}

#billing .pricing-table-wrapper h1 {
    margin: 0;
    text-align: center;
}

#billing .pricing-table-wrapper p {
    margin: 0;
    text-align: center;
}

#billing .pricing-table-wrapper .princing-description {
    margin-bottom: 20px;
}

#billing img {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 150px;
}

#billing button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-weight: 700;
}