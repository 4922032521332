#loading-screen {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#loading-screen .ant-spin {
    color: var(--orange)
}

#loading-screen .ant-spin-dot-item {
    background-color: var(--orange)
}