#order-summary {
    margin-bottom: 100px;
}

#order-summary .ant-descriptions-header {
    margin-bottom: 5px;
}

#order-summary .ant-descriptions-title {
    font-weight: 700;
}

#order-summary .ant-descriptions-view {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
}

#order-summary .ant-descriptions-item {
    padding: 0;
}

#order-summary-header {
    display: flex;
    justify-content: space-between;
}

#order-summary-header h3 {
    margin-top: 0;
    margin-bottom: 10px;
}

#order-summary-header button.delete-button{
    margin-left: 10px;
}